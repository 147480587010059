<template lang="pug">
.form.form---service
  .form__fields
    .form__field
      .input-text.input-text---white-background.input-text---input-weight-400
        input.input-text__input(type="text" placeholder="Ваше имя")

    .form__field
      .input-text.input-text---white-background.input-text---input-weight-400
        input.input-text__input(type="text" placeholder="+7 (_ _ _) _ _ _ - _ _ - _ _")

  .form__bottom
    .form__button
      .button.button---black.button---width
        .button__text Отправить

    .form__consent Нажимая кнопку, Вы соглашаетесь с <a href="#">политикой конфиденциальности данных</a>

</template>

<script>
export default {
  name: 'AppFormService'
}
</script>
