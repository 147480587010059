/*eslint-disable no-unused-vars*/

import { createApp } from 'vue'
import Maska from 'maska'
import form from '@/store/form'
import './assets/js/css/ImportCss'
import AppForm from '@/components/Form/AppForm.vue'
import AppFormAgent from '@/components/Form/AppFormAgent.vue'
import AppFormService from '@/components/Form/AppFormService.vue'
import { Fancybox } from '@fancyapps/ui'
import SwiperSlider from '@/assets/js/Swiper/Swiper'
import Tab from '@/assets/js/Tab/Tab'
import Faq from '@/assets/js/Faq/Faq'
import Info from '@/assets/js/Info/Info'
import MenuSub from '@/assets/js/Menu/Sub/MenuSub'
import MenuLeft from '@/assets/js/Menu/Left/MenuLeft'
import MenuMobile from '@/assets/js/Menu/Mobile/MenuMobile'
import MenuMobileSub from '@/assets/js/Menu/Mobile/Sub/MenuMobileSub'
import HeaderService from '@/assets/js/Header/Service/HeaderService'
import HeaderMobile from '@/assets/js/Header/Mobile/HeaderMobile'
import ProductTab from '@/assets/js/Product/Tab/ProductTab'

// Подключаем глобально jQuery
const $ = window.$ = window.jQuery = require('jquery')

document.addEventListener('DOMContentLoaded', () => {
    Fancybox.bind('[data-fancybox]', {
        mainClass: 'fancybox--popup'
    })

    new SwiperSlider()
    new Tab()
    new Faq()
    new Info()
    new MenuSub()
    new MenuLeft()
    new MenuMobile()
    new HeaderService()
    new HeaderMobile()
    new MenuMobileSub()
    new ProductTab()

    if(document.getElementById('block--form') != null) {
        createApp({})
            .component('block-form', AppForm)
            .use(Maska)
            .use(form)
            .mount('#block--form')
    }

    if(document.getElementById('block--form-agent') != null) {
        createApp({})
            .component('block-form-agent', AppFormAgent)
            .use(Maska)
            .use(form)
            .mount('#block--form-agent')
    }

    if(document.getElementById('block--form-service') != null) {
        createApp({})
            .component('block-form-service', AppFormService)
            .use(Maska)
            .use(form)
            .mount('#block--form-service')
    }
})
